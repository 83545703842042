import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <div style={{margin: "20px"}}>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>This page doesn't exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
